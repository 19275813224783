import React from 'react'
import './SocialBar.css'
import buzzFeed from '../../images/buzzfeed-opacity.png'
import eveningStandard from '../../images/es-opacity.png'
import productHunt from '../../images/ph-opacity.png'
import trench from '../../images/trench-opacity.png'
import complex from '../../images/complex-opacity.png'



export const SocialBar = () => 
  <div className='align-center'>
    <ul className='social-links'>
      <li id="first-list-item"><img src={buzzFeed} /></li>
      <li><img style={{width: '150px', paddingTop: '5px'}} src={eveningStandard} /></li>
      <li><img src={productHunt} /></li>
      <li><img src={trench} /></li>
      <li><img src={complex} /></li>
    </ul>
  </div>
 