import React from "react";
import { isMobile } from "react-device-detect";

import "./NavBar.css";

export const NavBar = () => (
  <div id="nav" className="header-right clearfix ">
    <div id="main-navigation">
      <ul>
        <li class="twitter-icon">
          <a href="https://vanillasocial.co" id="nav-link" target="_blank">
            vanilla
          </a>
        </li>
        <li class="facebook-icon">
          <a href="http://channelu.live" id="nav-link" target="_blank">
            channel u live
          </a>
        </li>
        <li class="instagram-icon">
          <a
            href="https://getstance.myshopify.com/"
            id="nav-link"
            target="_blank"
          >
            stance
          </a>
        </li>
      </ul>
    </div>

    <div class="menu-trigger">
      {/* <p id="menuTrigger">{isMobile ? "work" : "experiments"}</p> */}
    </div>
  </div>
);
