import React, { useState, useEffect } from "react";
// Assuming React Helmet is installed for SEO purposes
import { Helmet } from "react-helmet";

import "./App.css";
import { NavBar } from "./components/NavBar/NavBar";
import { SocialBar } from "./components/SocialBar/SocialBar";
import cultureTech from "./images/culture-tech.png";

const App = () => {
  const [home, toggleHomeView] = useState(true);
  const [menuActive, setMenuActive] = useState(false);

  const switchView = (e) => {
    if (e.target.id === "nav-link") return;

    const menuIds = ["menuTrigger", "nav", "main-navigation", "nav-link"];
    if (menuIds.includes(e.target.id)) {
      setMenuActive(!menuActive);
      return;
    }

    toggleHomeView(!home);
  };

  useEffect(() => {
    const handleTouchStart = (e) => switchView(e);
    const handleClick = (e) => switchView(e);

    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("click", handleClick);
    };
  }, [home, menuActive]);

  return (
    <div className="App">
      <Helmet>
        <title>Kudoscope - Technology Consultancy</title>
        <meta
          name="description"
          content="Kudoscope, a pioneer in the intersection of society, culture, and technology, is dedicated to exploring and developing innovative solutions in underexplored areas."
        />
        <meta
          name="keywords"
          content="Kudoscope, Startup Studio, Technology, Culture, Society, Innovation"
        />
      </Helmet>

      {home ? (
        <>
          <NavBar />
          <header className="App-header animated fadeIn slow">
            <h1>kudoscope</h1>
            <p className="slogan">building products in underserved markets</p>
          </header>
          <div style={{ opacity: 0.3 }}>
            <p>As Featured In</p>
          </div>
          <SocialBar />
        </>
      ) : (
        <header
          className="About-header animated fadeIn slow"
          style={{ backgroundColor: "#e67e22" }}
        >
          <div className="text-left about">
            <h3>
              kudoscope is a product studio working at the intersections of
              society, culture and tech. we're commited to experimenting,
              documenting and releasing projects in overlooked territories.{" "}
            </h3>
            <ul className="about-points">
              <li>
                + what big opportunities have been overlooked due to the blind
                spots of "traditional" entrepreneurs?
              </li>
              <li>
                + how can we ship MVPs faster and more frugally than the market?
              </li>
              <li>
                + what idea should we bring to life next weekend?{" "}
                <a href="mailto:samuel@kudoscope.com">hello!</a>
              </li>
            </ul>
          </div>
        </header>
      )}
    </div>
  );
};

export default App;
